<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <rect x="4" y="27.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="6" y="27.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="8" y="25.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="10" y="23.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="12" y="21.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="14" y="19.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="16" y="17.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="18" y="15.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="20" y="13.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="22" y="11.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="28" y="13.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="28" y="15.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="28" y="17.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="28" y="19.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="28" y="21.5" width="1" height="1" fill="#0B0C0A" />
    <rect x="25" y="21.5" width="1" height="1" fill="#0B0C0A" />
    <rect x="11" y="7.5" width="1" height="1" fill="#0B0C0A" />
    <rect x="11" y="4.5" width="1" height="1" fill="#0B0C0A" />
    <rect x="22" y="13.5" width="1" height="1" fill="#0B0C0A" />
    <rect x="19" y="10.5" width="1" height="1" fill="#0B0C0A" />
    <rect x="28" y="9.5" width="1" height="2" fill="#0B0C0A" />
    <rect x="26" y="6.5" width="1" height="1" fill="#0B0C0A" />
    <rect x="22" y="4.5" width="2" height="1" fill="#0B0C0A" />
    <rect x="28" y="11.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="24" y="13.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="24" y="19.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="24" y="17.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="24" y="15.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="26" y="21.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="26" y="9.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="26" y="7.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="4" y="25.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="6" y="23.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="8" y="21.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="10" y="19.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="12" y="17.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="14" y="15.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="16" y="13.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="18" y="11.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="20" y="9.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="16" y="3.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="14" y="3.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="12" y="7.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="14" y="7.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="16" y="7.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="18" y="7.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="10" y="5.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="12" y="3.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="20" y="3.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="18" y="3.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="22" y="5.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="24" y="5.5" width="2" height="2" fill="#0B0C0A" />
    <rect x="4" y="25.5" width="2" height="2" fill="white" fill-opacity="0.1" />
    <rect x="6" y="23.5" width="2" height="2" fill="white" fill-opacity="0.1" />
    <rect x="8" y="21.5" width="2" height="2" fill="white" fill-opacity="0.1" />
    <rect x="10" y="19.5" width="2" height="2" fill="white" fill-opacity="0.1" />
    <rect x="12" y="17.5" width="2" height="2" fill="white" fill-opacity="0.1" />
    <rect x="14" y="15.5" width="2" height="2" fill="white" fill-opacity="0.1" />
    <rect x="16" y="13.5" width="2" height="2" fill="white" fill-opacity="0.1" />
    <rect x="18" y="11.5" width="2" height="2" fill="white" fill-opacity="0.1" />
  </svg>
</template>
