import { defineStore } from 'pinia'

const usePreloaderStore = defineStore('preloader', {
  state: () => ({
    visible: false,
  }),
  actions: {
    show(): void {
      this.visible = true
    },
    hide(): void {
      this.visible = false
    },
  },
})

export { usePreloaderStore }
