{
  "basic": {
    "hello": "Hello",
    "start": "Start",
    "claim": "Claim",
    "loading": "Please wait, it's loading...",
    "edit": "Edit",
    "done": "Done!",
    "deposit": "Deposit",
    "custom_wallet": "custom wallet",
    "friends": "Friends",
    "world": "World",
    "top_5_by_ref": "TOP 5 \nleaders by referrals",
    "top_5_by_balance": "TOP 5 \nleaders by coins",
    "text_copy": "Link copied success",
    "teh_work": "TECHNICAL WORK",
    "not_found": "OPS... \nNOT FOUND",
    "not_enough_money": "Not enough money",
    "success": "Success",
    "start_farming": "Start farming",
    "farming_button": "Farming {amount} / sec.",
    "claim_coins": "Claim {amount}",
    "bonus": "Bonus:",
    "summary": "Total:",
    "score": "Score:",
    "back_to_home": "Return to Home",
    "game_over": "Game Over",
    "win_game": "Congratulations, you won!",
    "loose_game": "You lost, try again",
    "time": "Time",
    "score_user": "Score",
    "change_lang": "Change language to {lang}"
  },
  "header": {
    "close": "Close",
    "profit_for_one_tap": "Profit for 1 tap",
    "level": "level",
    "profit_per_hour": "Profit per hour",
    "level_number": "Lv.{level}"
  },
  "footer": {
    "main": "Farming",
    "store": "Mining",
    "task": "Task",
    "wallet": "Wallet",
    "invite_friends": "Friends"
  },
  "modals": {
    "crypto_modal": {
      "wallet_saved": "Wallet saved",
      "wallet_save_error": "Error! Please check the wallet you entered.",
      "address_text": "Write down the address of your USDT ERC-20 wallet to receive tokens",
      "have_account": "Do you have a {alias} account ?",
      "receive_rewards": "To receive rewards, you need to add your wallet address",
      "wallet_filled_title": "Wallet already filled",
      "wallet_filled_desc": "The wallet is already filled with you want to edit it?",
      "inputs": {
        "postal_address": {
          "placeholder": "Pre-postal address"
        }
      },
      "buttons": {
        "create_account": "Сreate an account",
        "there_are": "Yes, there are"
      }
    },
    "info_modal": {
      "titles": {
        "notify_3_hour": "Congratulations",
        "notify_24_hour": "Congratulations",
        "notify_wallet_connect": "Connect Your Wallet",
        "notify_new_level": "Level Up",
        "notify_tg_channel_sub": "To continue you need to subscribe to our Telegram channel"
      },
      "descriptions": {
        "notify_3_hour": "You've spent 3 hours in our app! Thank you for your activity.\n\nAs a reward, we offer you a special bonus. Would you like to claim it now?\n\nClick \"Claim Reward\" to receive the bonus, or \"Cancel\" if you prefer to do it later.",
        "notify_24_hour": "You've spent 24 hours in our app! Thank you for your activity.\n\nAs a reward, we offer you a special bonus. Would you like to claim it now?\n\nClick \"Claim Reward\" to receive the bonus, or \"Cancel\" if you prefer to do it later.",
        "notify_wallet_connect": "To receive a special bonus, connect your wallet to our app. It's quick and easy!\n\nClick \"Connect\" to get started, or \"Cancel\" if you prefer to do it later.",
        "notify_new_level": "You've successfully reached a new level in our app! Thank you for your activity and enthusiasm.\n\nKeep enjoying the app and reaching new heights!",
        "notify_tg_channel_sub": "Subscribe to our Telegram channel and stay up to date with the latest news. Take part in various community activities. Learn secrets and get rewards."
      },
      "buttons": {
        "notify_3_hour": "Claim Reward",
        "notify_24_hour": "Claim Reward",
        "notify_wallet_connect": "Connect",
        "notify_new_level": "Accept",
        "notify_tg_channel_sub": "Join"
      }
    }
  },
  "pages": {
    "store": {
      "card_char": {
        "tap_reward": "Tap Reward",
        "energy" : "Energy",
        "autofarm_duration" : "AutoFarming duration",
        "autofarm_reward" : "AutoFarming Reward",
        "game_daily_count" : "Daily Games count",
        "balance" : "Balance",
        "game_duration" : "Game duration",
        "game_reward_line" : "Line reward in game",
        "game_reward_win" : "Game Win Reward",
        "multitap" : "Multitap",
        "autofarm_notify": "AutoFarming notify"
      }
    },
    "invite_friends": {
      "title": "Invite friends",
      "desc": "You can take 10% of the amount your referrals brought you",
      "invite_a_friend": "Invite a friend",
      "for_you_and_your_friends": "For you and your friend",
      "empty_list_friends": "Referrals list empty"
    },
    "task": {
      "title": "Earn more coins",
      "statuses": {
        "done": "Done",
        "start": "start",
        "wait": "wait",
        "unavailable": "unavailable"
      }
    },
    "wallet": {
      "title": "Connect your wallet",
      "title_filled": "Your wallet {alias}"
    },
    "game": {
      "how_to_play": "How to play?",
      "finish_the_game": "Finish the game",
      "next_blocks": "Next blocks:",
      "no_attempts": "There's no attempt. Try again later",
      "count_attempts": "PLAY ({try_left}/{try_counts})",
      "instruction_for_game": {
        "game_instruction": "Game Instruction",
        "control": "Control:",
        "game_end": "Game over:",
        "end_game_condition": "The game ends when new pieces can no longer fit on the playing field. Also, the game automatically ends after 60 seconds. Try to score as many points as possible during this time!",
        "left_control": "Move the figure to the left: Use the left arrow on the keyboard or swipe left on the screen to move the figure to the left.",
        "right_control": "Move the figure to the right: Use the right arrow on the keyboard or right swipe on the screen to move the figure to the right.",
        "down_control": "Accelerate the fall: Press the down arrow on the keyboard or hold your finger on the screen to accelerate the figure's fall.",
        "rotate_control": "Rotate the figure: Press the up arrow on the keyboard or a short tap on the screen to rotate the figure clockwise."
      }
    }
  },
  "component": {
    "store_card": {
      "limit_is_used": "The limit is used",
      "buy_for": "Buy for"
    }
  }
}
