import { defineStore } from 'pinia'
import ApiService from '../api/ApiService'

const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {},
    notifyKeys: [
      'notify_new_level',
      'notify_tg_channel_sub',
      'notify_wallet_connect',
      'notify_3_hour',
      'notify_24_hour',
    ],
    needToShowModals: [],
  }),
  actions: {
    setUser(user: any): void {
      this.userInfo = user
    },
    setActiveModals() {
      for (const [key, value] of Object.entries(this.userInfo)) {
        if (this.notifyKeys.includes(key) && value === 1) {
          this.addToNeedToShowModalItem(key)
        }
      }
    },
    unsetActiveModal(key: string) {
      const index = this.needToShowModals.indexOf(key)
      if (index !== -1) {
        this.needToShowModals.splice(index, 1)
      }
    },
    clearActiveModal() {
      this.needToShowModals = []
    },
    addToNeedToShowModalItem(key: string) {
      this.needToShowModals.push(key)
    },
    async getUserFromApi() {
      this.userInfo = await ApiService.getInstance().getUserData()
    },
  },
})

export { useUserStore }
