<script lang="ts">
import { defineComponent } from 'vue'
import { usePreloaderStore } from '../stores/preloader'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'Preloader',
  setup() {
    const store = usePreloaderStore()
    const { visible } = storeToRefs(store)
    return {
      visible,
    }
  },
})
</script>

<template>
  <div v-if="visible" class="loader-overlay">
    <div class="loader">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="loader-text">{{ $t('basic.loading') }}</div>
  </div>
</template>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height: 100px;
}

.dot {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

.dot:nth-child(3) {
  animation-delay: 0s;
}

.dot:nth-child(4) {
  animation-delay: 0.16s;
}

.dot:nth-child(5) {
  animation-delay: 0.32s;
}

.loader-text {
  margin-top: 20px;
  color: white;
  font-size: 18px;
  text-align: center;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
</style>
