<script lang="ts">
import { defineComponent } from 'vue'
import VButton from '../base/VButton.vue'
import CloseIcon from '../icons/CloseIcon.vue'
import { useCryptoModalStore } from '../../stores/crypto-modal'
import { storeToRefs } from 'pinia'
import ApiService from '../../api/ApiService'
import { useToast } from 'vue-toastification'
import { useUserStore } from '../../stores/user'
import { WHITE_THEME, ORANGE_THEME } from '../../consts/buttonThemes'

export default defineComponent({
  name: 'CryptoModal',
  components: { CloseIcon, VButton },
  setup() {
    const store = useCryptoModalStore()
    const userStore = useUserStore()
    const toast = useToast()
    const { visible, alias, image, link, type } = storeToRefs(store)
    const { hide, setAlias } = store
    return {
      visible,
      alias,
      image,
      hide,
      link,
      toast,
      type,
      userStore,
      setAlias,
      WHITE_THEME,
      ORANGE_THEME,
    }
  },
  data() {
    return {
      step: 1,
      walletAddress: '',
      error: null,
      tonConnectUi: null,
    }
  },
  computed: {
    isFallback() {
      return this.type === 'fallback'
    },
    isFilled() {
      return this.userStore.userInfo.cryptoexchange === this.alias
    },
    isTon() {
      return this.alias.toLowerCase() === 'ton'
    },
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://unpkg.com/@tonconnect/ui@latest/dist/tonconnect-ui.min.js'
    script.async = true
    script.onload = () => {
      // Инициализация TonConnectUI после загрузки скрипта
      this.tonConnectUi = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl: 'https://mydom1827273655.com/tonconnect-manifest.json',
        buttonRootId: 'ton-connect',
      })

      this.tonConnectUi.uiOptions = {
        twaReturnUrl: 'https://t.me/testtetrisgamebot',
      }

      this.tonConnectUi.onStatusChange((wallet) => {
        if (!wallet) {
          return
        }

        const account = wallet.account
        if (account) {
          this.walletAddress = account.address
          this.setAlias('TON')
          this.save(true)
          return
        }
      })
    }

    script.onerror = () => {
      console.error('Failed to load the TonConnect UI script.')
    }

    document.head.appendChild(script)
  },
  methods: {
    async goToLink() {
      if (this.link.length > 0) {
        window.Telegram.WebApp.openLink(this.link)
      }
    },
    async save(isTonSave = false) {
      const response = await ApiService.getInstance().saveWallet(this.alias, this.walletAddress)
      if (response.status === 'fail') {
        this.error = this.$t('modals.crypto_modal.wallet_save_error')
        return
      }
      if (isTonSave) {
        this.tonConnectUi.disconnect()
      }
      this.toast.success(this.$t('modals.crypto_modal.wallet_saved'))
      await this.userStore.getUserFromApi()
      this.resetStep()
      this.hideModal()
    },
    openWalletSetting() {
      this.step = 2
    },
    resetStep() {
      this.step = 1
      this.error = null
    },
    hideModal() {
      this.hide()
      this.resetStep()
    },
  },
})
</script>

<template>
  <div v-show="visible" class="modal_crypto p-5">
    <header class="flex justify-between items-center">
      <div class="flex items-center">
        <img :src="image" alt="" class="mr-3 h-7" />
        <span class="text-2xl">{{ alias }}</span>
      </div>
      <CloseIcon @click="hideModal" />
    </header>
    <div v-if="step === 1 && !isFallback && !isFilled" class="text-center">
      <h1 class="text-3xl mb-2">{{ $t('modals.crypto_modal.have_account', { alias }) }}</h1>
      <p>{{ $t('modals.crypto_modal.receive_rewards') }}</p>
    </div>
    <div v-if="step === 2 || (isFallback && !isFilled)" class="text-center">
      <h1 class="text-3xl mb-2">
        {{ $t('basic.deposit') }}
        <span v-if="!isFallback">{{ alias }}</span>
        <span v-else>{{ $t('basic.custom_wallet') }}</span>
      </h1>
      <p>{{ $t('modals.crypto_modal.address_text') }}</p>
      <input
        v-model="walletAddress"
        type="text"
        class="mt-4"
        :placeholder="$t('modals.crypto_modal.inputs.postal_address.placeholder')"
      />
      <div v-if="error" class="mt-3">
        <span class="text-red-400">{{ error }}</span>
      </div>
    </div>
    <div v-if="isFilled" class="text-center">
      <h1 class="text-3xl mb-2">{{ $t('modals.crypto_modal.wallet_filled_title') }}</h1>
      <p>{{ $t('modals.crypto_modal.wallet_filled_desc') }}</p>
      <input
        v-model="walletAddress"
        type="text"
        class="mt-4"
        :placeholder="$t('modals.crypto_modal.inputs.postal_address.placeholder')"
      />
      <div v-if="error" class="mt-3">
        <span class="text-red-400">{{ error }}</span>
      </div>
    </div>
    <footer class="flex flex-col justify-center items-center">
      <VButton
        v-if="step === 1 && !isFallback && !isFilled && !isTon"
        :text="$t('modals.crypto_modal.buttons.there_are')"
        class="mb-2"
        :custom-text-classes="['font-pixelify', 'text-xl', 'font-bold', ORANGE_THEME]"
        :theme="ORANGE_THEME"
        @click="openWalletSetting"
      />
      <div id="tc-widget-root"><TcRoot></TcRoot></div>
      <div v-show="isTon" id="ton-connect" class="mb-2 text-center"></div>
      <VButton
        v-if="step === 1 && !isFallback && !isFilled && !isTon"
        :text="$t('modals.crypto_modal.buttons.create_account')"
        :custom-text-classes="['font-pixelify', 'text-xl', 'text-black', 'font-bold', WHITE_THEME]"
        :theme="WHITE_THEME"
        @click="goToLink"
      />
      <VButton
        v-if="step === 2 || isFallback || isFilled"
        :text="isFilled ? $t('basic.edit') : $t('basic.done')"
        :custom-text-classes="['font-pixelify', 'text-xl', 'font-bold', ORANGE_THEME]"
        :theme="ORANGE_THEME"
        @click="save"
      />
    </footer>
  </div>
</template>

<style scoped>
.modal_crypto {
  z-index: 99;
  position: absolute;
  bottom: 0;
  background: #1b1c1b;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 75vh;
  width: 100%;
  border: 2px solid #ffffff33;
  border-bottom: 0;
  border-radius: 12px 12px 0 0;
}
input {
  width: 75%;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 15px;
  background-color: #333;
  color: #fff;
  outline: none;
}

input::placeholder {
  color: #aaa;
}
</style>
