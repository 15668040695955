<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="9.5" width="20" height="2" fill="black" />
    <rect x="5" y="7.5" width="1" height="18" fill="black" />
    <rect x="6" y="8.5" width="1" height="1" fill="black" />
    <rect x="6" y="7.5" width="18" height="1" fill="black" />
    <rect x="26" y="10.5" width="1" height="6" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 25 7.5)" fill="black" />
    <rect width="18" height="1" transform="matrix(-1 0 0 1 24 6.5)" fill="black" />
    <rect width="20" height="2" transform="matrix(1 0 0 -1 6 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 5 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 6 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 6 12.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 25 12.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 25 25.5)" fill="black" />
    <rect x="27" y="26.5" width="1" height="6" transform="rotate(180 27 26.5)" fill="black" />
    <rect x="4" y="8.5" width="1" height="17" fill="black" />
    <rect width="1" height="5" transform="matrix(-1 0 0 1 28 11.5)" fill="black" />
    <rect width="1" height="5" transform="matrix(-1 0 0 1 28 20.5)" fill="black" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 27 17.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 24 16.5)" fill="black" />
    <rect width="3" height="1" transform="matrix(-1 0 0 1 26 15.5)" fill="black" />
    <rect width="1" height="2" transform="matrix(-1 0 0 1 23 17.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 24 19.5)" fill="black" />
    <rect width="3" height="1" transform="matrix(-1 0 0 1 26 20.5)" fill="black" />
    <rect x="4" y="8.5" width="1" height="17" fill="white" fill-opacity="0.1" />
    <rect x="5" y="7.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="6" y="6.5" width="18" height="1" fill="white" fill-opacity="0.1" />
  </svg>
</template>
