<script>
import VFooter from '../components/base/VFooter.vue'
import { RouterView, useRouter } from 'vue-router'
import CryptoModal from '../components/modals/CryptoModal.vue'
import { useCryptoModalStore } from '../stores/crypto-modal'
import { storeToRefs } from 'pinia'
import { onBeforeMount, ref } from 'vue'
import { useUserStore } from '../stores/user'
import { useBalanceStore } from '../stores/balance'
import { usePreloaderStore } from '../stores/preloader'
import { useI18n } from 'vue-i18n'
import ApiService from '../api/ApiService'
import InfoModal from '../components/modals/InfoModal.vue'
import { useInfoModalStore } from '../stores/info-modal'
export default {
  name: 'MainLayout',
  components: {
    CryptoModal,
    RouterView,
    VFooter,
    InfoModal,
  },
  setup() {
    const store = useCryptoModalStore()
    const { visible } = storeToRefs(store)
    const userStore = useUserStore()
    const balanceStore = useBalanceStore()
    const preloaderStore = usePreloaderStore()
    const infoModalStore = useInfoModalStore()
    const i18n = useI18n({ useScope: 'global' })
    const router = useRouter()
    const confirmDialogue = ref(null)
    const isBaseDataLoaded = ref(false)
    const getUserData = async () => {
      preloaderStore.show()
      const response = await ApiService.getInstance().updateAuth()
      if (response.status && response.status !== 'success') {
        const name = response.result === 'maintenance' ? 'teh_work' : response.result === 'ban' ? '404' : 'welcome'
        router
          .push({ name })
          .then(() => {
            preloaderStore.hide()
          })
          .catch((error) => {
            console.log(error)
          })
        return
      }
      await userStore.getUserFromApi()
      isBaseDataLoaded.value = true
      i18n.locale.value = userStore.userInfo.lang
      balanceStore.setBalance(userStore.userInfo.balance)
      userStore.setActiveModals()
      for (const key of userStore.needToShowModals) {
        infoModalStore.setType(key)
        if (key === 'notify_tg_channel_sub') {
          infoModalStore.setLink(userStore.userInfo.tg_sub_link)
        }
        const isConfirm = await confirmDialogue.value.showModal()
        if (isConfirm && key !== 'notify_new_level') {
          break
        }
      }
      userStore.clearActiveModal()
    }
    onBeforeMount(async () => {
      await getUserData()
    })
    return {
      visible,
      confirmDialogue,
      isBaseDataLoaded,
    }
  },
}
</script>

<template>
  <div class="min-h-screen h-full bg-black text-white flex flex-col items-center w-full">
    <div class="absolute inset-0 bg-cover bg-center bg-opacity-layer"></div>
    <div
      class="w-full p-4 rounded-lg justify-between flex flex-col flex-1 main-bg relative"
      :class="[visible ? 'opacity-50' : '']"
    >
      <Transition name="fade" mode="out-in">
        <RouterView v-if="isBaseDataLoaded" />
      </Transition>
      <VFooter />
    </div>
    <Transition name="slide-up" mode="out-in">
      <CryptoModal />
    </Transition>
    <Transition name="slide-up" mode="out-in">
      <InfoModal ref="confirmDialogue" />
    </Transition>
  </div>
</template>

<style scoped>
.main-bg {
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%, #1b1c1b 0%, #0b0c0a 100%);
}
.main-bg::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url('/image/bg-main.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s ease;
}
.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}
</style>
