<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="7.5" width="1" height="3" fill="black" />
    <rect width="1" height="3" transform="matrix(-1 0 0 1 27 7.5)" fill="black" />
    <rect x="5" y="10.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 10.5)" fill="black" />
    <rect x="5" y="11.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 11.5)" fill="black" />
    <rect x="5" y="12.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 12.5)" fill="black" />
    <rect x="10" y="11.5" width="1" height="1" fill="black" />
    <rect x="11" y="12.5" width="1" height="1" fill="black" />
    <rect x="11" y="17.5" width="1" height="1" fill="black" />
    <rect x="11" y="22.5" width="1" height="1" fill="black" />
    <rect x="12" y="11.5" width="1" height="1" fill="black" />
    <rect x="13" y="10.5" width="1" height="1" fill="black" />
    <rect x="5" y="13.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 13.5)" fill="black" />
    <rect x="5" y="14.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 14.5)" fill="black" />
    <rect x="5" y="15.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 15.5)" fill="black" />
    <rect x="5" y="16.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 16.5)" fill="black" />
    <rect x="5" y="17.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 17.5)" fill="black" />
    <rect x="5" y="18.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 18.5)" fill="black" />
    <rect x="5" y="19.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 19.5)" fill="black" />
    <rect x="5" y="20.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 20.5)" fill="black" />
    <rect x="5" y="21.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 21.5)" fill="black" />
    <rect x="5" y="22.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 22.5)" fill="black" />
    <rect x="5" y="23.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 23.5)" fill="black" />
    <rect x="5" y="24.5" width="1" height="3" fill="black" />
    <rect width="1" height="3" transform="matrix(-1 0 0 1 27 24.5)" fill="black" />
    <rect x="6" y="6.5" width="1" height="22" fill="black" />
    <rect width="1" height="22" transform="matrix(-1 0 0 1 26 6.5)" fill="black" />
    <rect x="8" y="29.5" width="3" height="1" fill="black" />
    <rect x="24" y="5.5" width="3" height="1" transform="rotate(180 24 5.5)" fill="black" />
    <rect width="3" height="1" transform="matrix(-1 0 0 1 24 29.5)" fill="black" />
    <rect width="3" height="1" transform="matrix(1 0 0 -1 8 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 21 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 11 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 20 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 12 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 12 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 19 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 13 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 13 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 13 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 18 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 14 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 14 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 14 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 15 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 15 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 16 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 16 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 16 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 17 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 17 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 17 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 14 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 18 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 18 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 18 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 13 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 19 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 19 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 12 29.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 20 5.5)" fill="black" />
    <rect x="7" y="28.5" width="4" height="1" fill="black" />
    <rect x="25" y="6.5" width="4" height="1" transform="rotate(180 25 6.5)" fill="black" />
    <rect width="4" height="1" transform="matrix(-1 0 0 1 25 28.5)" fill="black" />
    <rect width="4" height="1" transform="matrix(1 0 0 -1 7 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 21 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 11 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 25 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 7 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 8 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 24 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 20 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 12 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 19 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 13 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 13 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 14 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 18 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 15 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 14 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 16 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 17 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 15 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 18 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 16 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 16 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 17 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 14 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 18 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 13 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 19 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 12 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 20 6.5)" fill="black" />
    <rect x="16" y="12.5" width="7" height="1" fill="black" />
    <rect x="16" y="17.5" width="7" height="1" fill="black" />
    <rect x="16" y="22.5" width="7" height="1" fill="black" />
    <rect x="5" y="7.5" width="1" height="20" fill="white" fill-opacity="0.1" />
    <rect x="6" y="6.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="7" y="5.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="8" y="4.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="9" y="4.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="10" y="4.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="11" y="4.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="12" y="4.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="12" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="13" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="13" y="2.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="14" y="2.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="15" y="2.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="16" y="2.5" width="1" height="1" fill="white" fill-opacity="0.1" />
  </svg>
</template>
