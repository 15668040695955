<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13" y="9.5" width="1" height="1" fill="black" />
    <rect x="19" y="19.5" width="1" height="1" transform="rotate(180 19 19.5)" fill="black" />
    <rect x="25" y="13.5" width="1" height="1" transform="rotate(180 25 13.5)" fill="black" />
    <rect x="19" y="23.5" width="1" height="1" transform="rotate(180 19 23.5)" fill="black" />
    <rect x="25" y="15.5" width="1" height="1" transform="rotate(180 25 15.5)" fill="black" />
    <rect x="14" y="9.5" width="1" height="1" fill="black" />
    <rect x="18" y="19.5" width="1" height="1" transform="rotate(180 18 19.5)" fill="black" />
    <rect x="24" y="13.5" width="1" height="1" transform="rotate(180 24 13.5)" fill="black" />
    <rect x="18" y="23.5" width="1" height="1" transform="rotate(180 18 23.5)" fill="black" />
    <rect x="24" y="15.5" width="1" height="1" transform="rotate(180 24 15.5)" fill="black" />
    <rect x="15" y="9.5" width="1" height="1" fill="black" />
    <rect x="17" y="19.5" width="1" height="1" transform="rotate(180 17 19.5)" fill="black" />
    <rect x="17" y="23.5" width="1" height="1" transform="rotate(180 17 23.5)" fill="black" />
    <rect x="16" y="9.5" width="1" height="1" fill="black" />
    <rect x="22" y="3.5" width="1" height="1" fill="black" />
    <rect x="19" y="3.5" width="1" height="1" fill="black" />
    <rect x="16" y="19.5" width="1" height="1" transform="rotate(180 16 19.5)" fill="black" />
    <rect x="16" y="23.5" width="1" height="1" transform="rotate(180 16 23.5)" fill="black" />
    <rect x="23" y="3.5" width="1" height="1" fill="black" />
    <rect x="17" y="9.5" width="1" height="1" fill="black" />
    <rect x="20" y="3.5" width="1" height="1" fill="black" />
    <rect x="15" y="19.5" width="1" height="1" transform="rotate(180 15 19.5)" fill="black" />
    <rect x="15" y="23.5" width="1" height="1" transform="rotate(180 15 23.5)" fill="black" />
    <rect x="11" y="25.5" width="1" height="1" transform="rotate(180 11 25.5)" fill="black" />
    <rect x="10" y="26.5" width="1" height="1" transform="rotate(180 10 26.5)" fill="black" />
    <rect x="9" y="27.5" width="1" height="1" transform="rotate(180 9 27.5)" fill="black" />
    <rect x="24" y="27.5" width="1" height="1" transform="rotate(180 24 27.5)" fill="black" />
    <rect x="30" y="19.5" width="1" height="1" transform="rotate(180 30 19.5)" fill="black" />
    <rect x="9" y="28.5" width="1" height="1" transform="rotate(180 9 28.5)" fill="black" />
    <rect x="10" y="28.5" width="1" height="1" transform="rotate(180 10 28.5)" fill="black" />
    <rect x="11" y="28.5" width="1" height="1" transform="rotate(180 11 28.5)" fill="black" />
    <rect x="12" y="28.5" width="1" height="1" transform="rotate(180 12 28.5)" fill="black" />
    <rect x="13" y="28.5" width="1" height="1" transform="rotate(180 13 28.5)" fill="black" />
    <rect x="9" y="29.5" width="1" height="1" transform="rotate(180 9 29.5)" fill="black" />
    <rect x="8" y="29.5" width="1" height="1" transform="rotate(180 8 29.5)" fill="black" />
    <rect x="10" y="29.5" width="1" height="1" transform="rotate(180 10 29.5)" fill="black" />
    <rect x="11" y="29.5" width="1" height="1" transform="rotate(180 11 29.5)" fill="black" />
    <rect x="12" y="29.5" width="1" height="1" transform="rotate(180 12 29.5)" fill="black" />
    <rect x="13" y="29.5" width="1" height="1" transform="rotate(180 13 29.5)" fill="black" />
    <rect x="14" y="29.5" width="1" height="1" transform="rotate(180 14 29.5)" fill="black" />
    <rect x="15" y="29.5" width="1" height="1" transform="rotate(180 15 29.5)" fill="black" />
    <rect x="16" y="29.5" width="1" height="1" transform="rotate(180 16 29.5)" fill="black" />
    <rect x="17" y="29.5" width="1" height="1" transform="rotate(180 17 29.5)" fill="black" />
    <rect x="18" y="29.5" width="1" height="1" transform="rotate(180 18 29.5)" fill="black" />
    <rect x="19" y="29.5" width="1" height="1" transform="rotate(180 19 29.5)" fill="black" />
    <rect x="20" y="29.5" width="1" height="1" transform="rotate(180 20 29.5)" fill="black" />
    <rect x="21" y="29.5" width="1" height="1" transform="rotate(180 21 29.5)" fill="black" />
    <rect x="22" y="29.5" width="1" height="1" transform="rotate(180 22 29.5)" fill="black" />
    <rect x="23" y="29.5" width="1" height="1" transform="rotate(180 23 29.5)" fill="black" />
    <rect x="24" y="29.5" width="1" height="1" transform="rotate(180 24 29.5)" fill="black" />
    <rect x="25" y="29.5" width="1" height="1" transform="rotate(180 25 29.5)" fill="black" />
    <rect x="8" y="28.5" width="1" height="1" transform="rotate(180 8 28.5)" fill="black" />
    <rect x="8" y="27.5" width="1" height="1" transform="rotate(180 8 27.5)" fill="black" />
    <rect x="9" y="26.5" width="1" height="1" transform="rotate(180 9 26.5)" fill="black" />
    <rect x="10" y="25.5" width="1" height="1" transform="rotate(180 10 25.5)" fill="black" />
    <rect x="11" y="24.5" width="1" height="1" transform="rotate(180 11 24.5)" fill="black" />
    <rect x="12" y="23.5" width="1" height="1" transform="rotate(180 12 23.5)" fill="black" />
    <rect x="13" y="23.5" width="1" height="1" transform="rotate(180 13 23.5)" fill="black" />
    <rect x="23" y="28.5" width="1" height="1" transform="rotate(180 23 28.5)" fill="black" />
    <rect x="22" y="28.5" width="1" height="1" transform="rotate(180 22 28.5)" fill="black" />
    <rect x="21" y="28.5" width="1" height="1" transform="rotate(180 21 28.5)" fill="black" />
    <rect x="20" y="28.5" width="1" height="1" transform="rotate(180 20 28.5)" fill="black" />
    <rect x="24" y="28.5" width="1" height="1" transform="rotate(180 24 28.5)" fill="black" />
    <rect x="24" y="3.5" width="1" height="1" fill="black" />
    <rect x="30" y="20.5" width="8" height="1" transform="rotate(180 30 20.5)" fill="black" />
    <rect x="18" y="9.5" width="1" height="1" fill="black" />
    <rect x="21" y="3.5" width="1" height="1" fill="black" />
    <rect x="14" y="19.5" width="1" height="1" transform="rotate(180 14 19.5)" fill="black" />
    <rect x="14" y="20.5" width="1" height="1" transform="rotate(180 14 20.5)" fill="black" />
    <rect x="15" y="20.5" width="1" height="1" transform="rotate(180 15 20.5)" fill="black" />
    <rect x="17" y="20.5" width="1" height="1" transform="rotate(180 17 20.5)" fill="black" />
    <rect x="16" y="20.5" width="1" height="1" transform="rotate(180 16 20.5)" fill="black" />
    <rect x="18" y="20.5" width="1" height="1" transform="rotate(180 18 20.5)" fill="black" />
    <rect x="19" y="20.5" width="1" height="1" transform="rotate(180 19 20.5)" fill="black" />
    <rect x="19" y="23.5" width="6" height="1" transform="rotate(180 19 23.5)" fill="black" />
    <rect x="12" y="24.5" width="1" height="1" transform="rotate(180 12 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 19 23.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 18 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 19 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 17 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 16 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 15 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 14 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 13 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 12 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 20 23.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 21 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 22 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 23 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 24 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 24 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 25 16.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 21 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 27 17.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 22 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 28 18.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 20 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 26 16.5)" fill="black" />
    <rect x="21" y="11.5" width="1" height="1" transform="rotate(90 21 11.5)" fill="black" />
    <rect x="27" y="5.5" width="1" height="1" transform="rotate(90 27 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 17 5.5)" fill="black" />
    <rect x="21" y="12.5" width="1" height="1" transform="rotate(90 21 12.5)" fill="black" />
    <rect x="27" y="6.5" width="1" height="1" transform="rotate(90 27 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 17 6.5)" fill="black" />
    <rect x="21" y="13.5" width="1" height="1" transform="rotate(90 21 13.5)" fill="black" />
    <rect x="27" y="7.5" width="1" height="1" transform="rotate(90 27 7.5)" fill="black" />
    <rect x="21" y="15.5" width="1" height="1" transform="rotate(90 21 15.5)" fill="black" />
    <rect x="27" y="9.5" width="1" height="1" transform="rotate(90 27 9.5)" fill="black" />
    <rect x="21" y="14.5" width="1" height="1" transform="rotate(90 21 14.5)" fill="black" />
    <rect x="27" y="8.5" width="1" height="1" transform="rotate(90 27 8.5)" fill="black" />
    <rect x="21" y="16.5" width="1" height="1" transform="rotate(90 21 16.5)" fill="black" />
    <rect x="27" y="10.5" width="1" height="1" transform="rotate(90 27 10.5)" fill="black" />
    <rect x="11" y="15.5" width="1" height="1" transform="rotate(-90 11 15.5)" fill="black" />
    <rect x="11" y="17.5" width="1" height="1" transform="rotate(-90 11 17.5)" fill="black" />
    <rect x="11" y="14.5" width="1" height="1" transform="rotate(-90 11 14.5)" fill="black" />
    <rect x="11" y="16.5" width="1" height="1" transform="rotate(-90 11 16.5)" fill="black" />
    <rect x="11" y="13.5" width="1" height="1" transform="rotate(-90 11 13.5)" fill="black" />
    <rect x="13" y="11.5" width="1" height="1" transform="rotate(-180 13 11.5)" fill="black" />
    <rect x="19" y="17.5" width="1" height="1" fill="black" />
    <rect x="19" y="18.5" width="1" height="1" fill="black" />
    <rect x="20" y="17.5" width="1" height="1" fill="black" />
    <rect x="21" y="16.5" width="1" height="1" fill="black" />
    <rect x="21" y="15.5" width="1" height="1" fill="black" />
    <rect x="21" y="14.5" width="1" height="1" fill="black" />
    <rect x="21" y="13.5" width="1" height="1" fill="black" />
    <rect x="21" y="12.5" width="1" height="1" fill="black" />
    <rect x="21" y="11.5" width="1" height="1" fill="black" />
    <rect x="20" y="10.5" width="1" height="1" fill="black" />
    <rect x="19" y="9.5" width="1" height="1" fill="black" />
    <rect x="18" y="8.5" width="1" height="1" fill="black" />
    <rect x="17" y="8.5" width="1" height="1" fill="black" />
    <rect x="16" y="8.5" width="1" height="1" fill="black" />
    <rect x="15" y="8.5" width="1" height="1" fill="black" />
    <rect x="14" y="8.5" width="1" height="1" fill="black" />
    <rect x="13" y="8.5" width="1" height="1" fill="black" />
    <rect x="12" y="9.5" width="1" height="1" fill="black" />
    <rect x="11" y="10.5" width="1" height="1" fill="black" />
    <rect x="10" y="13.5" width="1" height="1" fill="black" />
    <rect x="10" y="12.5" width="1" height="1" fill="black" />
    <rect x="10" y="11.5" width="1" height="1" fill="black" />
    <rect x="10" y="14.5" width="1" height="1" fill="black" />
    <rect x="10" y="15.5" width="1" height="1" fill="black" />
    <rect x="10" y="16.5" width="1" height="1" fill="black" />
    <rect x="11" y="17.5" width="1" height="1" fill="black" />
    <rect x="12" y="18.5" width="1" height="1" fill="black" />
    <rect x="25" y="11.5" width="1" height="1" fill="black" />
    <rect x="20" y="11.5" width="1" height="1" transform="rotate(-180 20 11.5)" fill="black" />
    <rect x="26" y="5.5" width="1" height="1" transform="rotate(-180 26 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 -3.85215e-08 -3.85215e-08 -1 18 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 7 13.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 7 15.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 8 13.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 8 15.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 10 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 13 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 9 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 12 3.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 2 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 8 3.5)" fill="black" />
    <rect width="8" height="1" transform="matrix(1 0 0 -1 2 20.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 11 3.5)" fill="black" />
    <rect x="7" y="16.5" width="1" height="1" transform="rotate(180 7 16.5)" fill="black" />
    <rect x="5" y="17.5" width="1" height="1" transform="rotate(180 5 17.5)" fill="black" />
    <rect x="4" y="18.5" width="1" height="1" transform="rotate(180 4 18.5)" fill="black" />
    <rect x="6" y="16.5" width="1" height="1" transform="rotate(180 6 16.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 5 5.5)" fill="black" />
    <rect x="15" y="5.5" width="1" height="1" transform="rotate(90 15 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 5 6.5)" fill="black" />
    <rect x="15" y="6.5" width="1" height="1" transform="rotate(90 15 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 5 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 5 9.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 5 8.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-5.18986e-09 1 1 5.18986e-09 5 10.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 3.85215e-08 3.85215e-08 1 7 11.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 -3.85215e-08 -3.85215e-08 -1 6 5.5)" fill="black" />
    <rect x="14" y="5.5" width="1" height="1" transform="rotate(-180 14 5.5)" fill="black" />
    <rect x="12" y="17.5" width="1" height="1" fill="black" />
    <rect x="11" y="12.5" width="1" height="1" transform="rotate(-90 11 12.5)" fill="black" />
    <rect x="10" y="12.5" width="1" height="1" transform="rotate(-90 10 12.5)" fill="white" fill-opacity="0.1" />
    <rect x="10" y="13.5" width="1" height="1" transform="rotate(-90 10 13.5)" fill="white" fill-opacity="0.1" />
    <rect x="10" y="14.5" width="1" height="1" transform="rotate(-90 10 14.5)" fill="white" fill-opacity="0.1" />
    <rect x="10" y="15.5" width="1" height="1" transform="rotate(-90 10 15.5)" fill="white" fill-opacity="0.1" />
    <rect x="10" y="16.5" width="1" height="1" transform="rotate(-90 10 16.5)" fill="white" fill-opacity="0.1" />
    <rect x="10" y="17.5" width="1" height="1" transform="rotate(-90 10 17.5)" fill="white" fill-opacity="0.1" />
    <rect x="7" y="27.5" width="1" height="1" transform="rotate(-90 7 27.5)" fill="white" fill-opacity="0.1" />
    <rect x="7" y="28.5" width="1" height="1" transform="rotate(-90 7 28.5)" fill="white" fill-opacity="0.1" />
    <rect x="7" y="29.5" width="1" height="1" transform="rotate(-90 7 29.5)" fill="white" fill-opacity="0.1" />
    <rect x="8" y="26.5" width="1" height="1" transform="rotate(-90 8 26.5)" fill="white" fill-opacity="0.1" />
    <rect x="9" y="25.5" width="1" height="1" transform="rotate(-90 9 25.5)" fill="white" fill-opacity="0.1" />
    <rect x="10" y="24.5" width="1" height="1" transform="rotate(-90 10 24.5)" fill="white" fill-opacity="0.1" />
    <rect x="11" y="23.5" width="1" height="1" transform="rotate(-90 11 23.5)" fill="white" fill-opacity="0.1" />
    <rect x="12" y="22.5" width="1" height="1" transform="rotate(-90 12 22.5)" fill="white" fill-opacity="0.1" />
    <rect x="13" y="22.5" width="1" height="1" transform="rotate(-90 13 22.5)" fill="white" fill-opacity="0.1" />
    <rect x="14" y="22.5" width="1" height="1" transform="rotate(-90 14 22.5)" fill="white" fill-opacity="0.1" />
    <rect x="11" y="11.5" width="1" height="1" transform="rotate(-90 11 11.5)" fill="white" fill-opacity="0.1" />
    <rect x="12" y="10.5" width="1" height="1" transform="rotate(-90 12 10.5)" fill="white" fill-opacity="0.1" />
    <rect x="13" y="9.5" width="1" height="1" transform="rotate(-90 13 9.5)" fill="white" fill-opacity="0.1" />
    <rect x="14" y="9.5" width="1" height="1" transform="rotate(-90 14 9.5)" fill="white" fill-opacity="0.1" />
    <rect x="15" y="9.5" width="1" height="1" transform="rotate(-90 15 9.5)" fill="white" fill-opacity="0.1" />
    <rect x="16" y="9.5" width="1" height="1" transform="rotate(-90 16 9.5)" fill="white" fill-opacity="0.1" />
  </svg>
</template>
