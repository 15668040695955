import { defineStore } from 'pinia'

const useCryptoModalStore = defineStore('crypto-modal', {
  state: () => ({
    visible: false,
    alias: '',
    image: '',
    link: '',
    type: '',
  }),
  actions: {
    hide(): void {
      this.visible = false
    },
    show(): void {
      this.visible = true
    },
    setAlias(alias: string) {
      this.alias = alias
    },
    setImage(image: string) {
      this.image = image
    },
    setLink(link: string) {
      this.link = link
    },
    setType(type: string) {
      this.type = type
    },
  },
})

export { useCryptoModalStore }
