<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CloseIcon',
})
</script>

<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="2" height="2" transform="matrix(-1 0 0 1 17 15.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 15 17.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 13 19.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 15 13.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 13 11.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 19 13.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 21 11.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 19 17.5)" fill="#EFEFEF" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 21 19.5)" fill="#EFEFEF" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 21 20.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 19 18.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 16.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 18.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 13 20.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 14.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 13 12.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 19 14.5)" fill="black" fill-opacity="0.2" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 21 12.5)" fill="black" fill-opacity="0.2" />
  </svg>
</template>

<style scoped></style>
