<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'VLink',
  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
})
</script>

<template>
  <RouterLink exact-active-class="active-class" class="flex flex-col items-center" :to="{ name }">
    <slot></slot> {{ $t(`footer.${name}`) }}
  </RouterLink>
</template>

<style scoped>
.active-class {
  background-color: #0b0c0a0d;
}
</style>
