import { defineStore } from 'pinia'
import ApiService from '../api/ApiService'

const useInfoModalStore = defineStore('info-modal', {
  state: () => ({
    redirectTo: '',
    type: '',
    link: '',
    visible: false,
  }),
  actions: {
    async close() {
      await ApiService.getInstance().closeInfoModal(this.type)
    },
    setRedirectTo(value: string) {
      this.redirectTo = value
    },
    setType(value: string) {
      this.type = value
    },
    setLink(value: string) {
      this.link = value
    },
    hide() {
      this.visible = false
    },
    show() {
      this.visible = true
    },
  },
})

export { useInfoModalStore }
