import { defineStore } from 'pinia'

const useBalanceStore = defineStore('balance', {
  state: () => ({
    balance: 0,
  }),
  actions: {
    incrementBalance(value: number): void {
      this.balance += value
    },
    decrementBalance(value: number) {
      this.balance -= value
    },
    setBalance(value: number): void {
      this.balance = value
    },
  },
})

export { useBalanceStore }
