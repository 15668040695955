<script lang="ts">
import { defineComponent } from 'vue'
import VButton from '../base/VButton.vue'
import CloseIcon from '../icons/CloseIcon.vue'
import { storeToRefs } from 'pinia'
import { ORANGE_THEME } from '../../consts/buttonThemes'
import { useInfoModalStore } from '../../stores/info-modal'
import { usePreloaderStore } from '../../stores/preloader'

export default defineComponent({
  name: 'InfoModal',
  components: { CloseIcon, VButton },
  setup() {
    const store = useInfoModalStore()
    const preloaderStore = usePreloaderStore()
    const { redirectTo, type, link, visible } = storeToRefs(store)
    const { close, hide, show } = store
    return {
      visible,
      redirectTo,
      preloaderStore,
      type,
      show,
      link,
      close,
      hide,
      ORANGE_THEME,
    }
  },
  data() {
    return {
      redirectPath: {
        notify_wallet_connect: 'wallet',
        notify_3_hour: 'task',
        notify_24_hour: 'task',
      },
      resolvePromise: undefined,
      rejectPromise: undefined,
    }
  },
  computed: {},
  methods: {
    async goToLink() {
      if (this.link.length > 0) {
        if (this.type !== 'notify_tg_channel_sub') {
          window.Telegram.WebApp.openLink(this.link)
          await this.close()
        }
        window.Telegram.WebApp.openTelegramLink(this.link)
      }
    },
    async handleClick() {
      if (this.type !== 'notify_tg_channel_sub') {
        await this.close()
        if (this.redirectPath[this.type]) {
          await this.$router.push(this.redirectPath[this.type])
          this.hide()
          return
        }
      }
      await this.goToLink()
      this.hide()
    },
    async hideModal() {
      if (this.type !== 'notify_tg_channel_sub') {
        await this.close()
      }
      this.hide()
    },
    showModal() {
      this.show()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async _confirm() {
      this.preloaderStore.show()
      await this.handleClick()
      this.resolvePromise(true)
      this.preloaderStore.hide()
    },
    async _cancel() {
      this.preloaderStore.show()
      await this.hideModal()
      this.resolvePromise(false)
      this.preloaderStore.hide()
    },
  },
})
</script>

<template>
  <div class="w-full h-full z-50">
    <div v-show="visible" class="modal-overlay"></div>
    <div v-show="visible" class="modal_crypto p-5">
      <header class="flex justify-between items-center">
        <div class="flex items-center">
          <span class="text-3xl">{{ $t(`modals.info_modal.titles.${type}`) }}</span>
        </div>
        <CloseIcon v-if="type !== 'notify_tg_channel_sub'" @click="_cancel" />
      </header>
      <div class="text-center">
        <p>{{ $t(`modals.info_modal.descriptions.${type}`) }}</p>
      </div>
      <footer class="flex flex-col justify-center items-center">
        <VButton
          :text="$t(`modals.info_modal.buttons.${type}`)"
          class="mb-2"
          :custom-text-classes="['font-pixelify', 'text-xl', 'font-bold', ORANGE_THEME]"
          :theme="ORANGE_THEME"
          @click="_confirm"
        />
      </footer>
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 98;
}
.modal_crypto {
  z-index: 99;
  position: absolute;
  bottom: 0;
  background: #1b1c1b;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 55vh;
  width: 100%;
  border: 2px solid #ffffff33;
  border-bottom: 0;
  border-radius: 12px 12px 0 0;
}
input {
  width: 75%;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 15px;
  background-color: #333;
  color: #fff;
  outline: none;
}

input::placeholder {
  color: #aaa;
}
</style>
