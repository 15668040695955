<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="29.5" width="1" height="1" fill="black" />
    <rect x="11" y="20.5" width="1" height="1" fill="black" />
    <rect x="17" y="14.5" width="1" height="1" fill="black" />
    <rect x="13" y="29.5" width="1" height="1" fill="black" />
    <rect x="14" y="29.5" width="1" height="1" fill="black" />
    <rect x="15" y="29.5" width="1" height="1" fill="black" />
    <rect x="16" y="29.5" width="1" height="1" fill="black" />
    <rect x="17" y="29.5" width="1" height="1" fill="black" />
    <rect x="18" y="29.5" width="1" height="1" fill="black" />
    <rect x="12" y="3.5" width="1" height="1" fill="black" />
    <rect x="13" y="3.5" width="1" height="1" fill="black" />
    <rect x="14" y="3.5" width="1" height="1" fill="black" />
    <rect x="15" y="3.5" width="1" height="1" fill="black" />
    <rect x="16" y="3.5" width="1" height="1" fill="black" />
    <rect x="17" y="3.5" width="1" height="1" fill="black" />
    <rect x="18" y="3.5" width="1" height="1" fill="black" />
    <rect x="19" y="28.5" width="1" height="1" fill="black" />
    <rect x="20" y="28.5" width="1" height="1" fill="black" />
    <rect x="21" y="27.5" width="1" height="1" fill="black" />
    <rect x="22" y="27.5" width="1" height="1" fill="black" />
    <rect x="23" y="26.5" width="1" height="1" fill="black" />
    <rect x="24" y="25.5" width="1" height="1" fill="black" />
    <rect x="25" y="24.5" width="1" height="1" fill="black" />
    <rect x="26" y="23.5" width="1" height="1" fill="black" />
    <rect x="26" y="22.5" width="1" height="1" fill="black" />
    <rect x="27" y="21.5" width="1" height="1" fill="black" />
    <rect x="27" y="20.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 19 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 20 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 21 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 22 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 23 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 24 8.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 25 9.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 26 10.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 26 11.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 27 12.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(1 0 0 -1 27 13.5)" fill="black" />
    <rect x="28" y="19.5" width="1" height="1" fill="black" />
    <rect x="28" y="18.5" width="1" height="1" fill="black" />
    <rect x="28" y="17.5" width="1" height="1" fill="black" />
    <rect x="28" y="16.5" width="1" height="1" fill="black" />
    <rect x="28" y="15.5" width="1" height="1" fill="black" />
    <rect x="28" y="14.5" width="1" height="1" fill="black" />
    <rect x="28" y="13.5" width="1" height="1" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 12 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 11 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 10 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 9 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 8 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 7 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 6 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 5 23.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 5 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 21.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 20.5)" fill="black" />
    <rect x="12" y="5.5" width="1" height="1" transform="rotate(180 12 5.5)" fill="black" />
    <rect x="11" y="5.5" width="1" height="1" transform="rotate(180 11 5.5)" fill="black" />
    <rect x="10" y="6.5" width="1" height="1" transform="rotate(180 10 6.5)" fill="black" />
    <rect x="13" y="5.5" width="1" height="1" transform="rotate(180 13 5.5)" fill="black" />
    <rect x="16" y="5.5" width="1" height="1" transform="rotate(180 16 5.5)" fill="black" />
    <rect x="9" y="6.5" width="1" height="1" transform="rotate(180 9 6.5)" fill="black" />
    <rect x="8" y="7.5" width="1" height="1" transform="rotate(180 8 7.5)" fill="black" />
    <rect x="14" y="5.5" width="1" height="1" transform="rotate(180 14 5.5)" fill="black" />
    <rect x="11" y="6.5" width="1" height="1" transform="rotate(180 11 6.5)" fill="black" />
    <rect x="17" y="5.5" width="1" height="1" transform="rotate(180 17 5.5)" fill="black" />
    <rect x="7" y="8.5" width="1" height="1" transform="rotate(180 7 8.5)" fill="black" />
    <rect x="6" y="9.5" width="1" height="1" transform="rotate(180 6 9.5)" fill="black" />
    <rect x="5" y="10.5" width="1" height="1" transform="rotate(180 5 10.5)" fill="black" />
    <rect x="5" y="11.5" width="1" height="1" transform="rotate(180 5 11.5)" fill="black" />
    <rect x="13" y="18.5" width="1" height="1" transform="rotate(180 13 18.5)" fill="black" />
    <rect x="16" y="15.5" width="1" height="1" transform="rotate(180 16 15.5)" fill="black" />
    <rect x="4" y="12.5" width="1" height="1" transform="rotate(180 4 12.5)" fill="black" />
    <rect x="4" y="13.5" width="1" height="1" transform="rotate(180 4 13.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 3 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 3 18.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 3 17.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 3 16.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 3 15.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 3 14.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 18.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 17.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 16.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 15.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 14.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 5 12.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 6 10.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 13.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 5 11.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 6 9.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 7 8.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 8 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 20 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 21 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 23 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 22 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 23 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 24 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 9 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 12 5.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 18 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 19 4.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 14.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 12.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 5 21.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 6 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 6 23.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 13 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 14 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 16 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 18 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 19 28.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 16 12.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 20.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 15 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 12 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 16 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 16 18.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 16 15.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 18.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 18 18.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 18 17.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 15.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 17 14.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 21 16.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 11 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 9 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 9 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 8 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 8 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 7 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 7 23.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 12 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 10 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 21 27.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 22 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 23 26.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 23 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 24 25.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 24 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 25 24.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 25 23.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 26 23.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 26 22.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 21.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 28 19.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 28 17.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 28 15.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 20.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 28 18.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 28 16.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 28 14.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 28 13.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 12.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 26 10.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 27 11.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 26 9.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 25 9.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 25 8.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 24 8.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 5 20.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 7 9.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 8 8.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 9 7.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 10 6.5)" fill="black" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 3 13.5)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 10.7867 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 21.52)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 21.52)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 21.52)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 21.9131)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 21.9131)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 21.52)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 21.52)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 21.52)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 21.1265)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 20.7334)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 20.3398)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 19.9468)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 19.5532)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 19.1597)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 18.7666)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.1467 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.7533 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 12.36 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.9667 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.5734 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 11.18 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 18.373)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 17.98)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 17.5864)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 17.1934)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.4066 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 21.0133 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.62 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 20.2267 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.8334 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.6533 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 14.4399)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 14.0464)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 19.44 15.2266)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 18.2599 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 13.6533)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 13.2598)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 12.8667)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.4733 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.8666 12.0801)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 11.6865)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 11.6865)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 11.293)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 11.6865)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 11.6865)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 11.6865)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 11.293)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 11.6865)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 12.4731)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 14.833)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.54 15.6196)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 17.08 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.2933 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.5067 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.72 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 16.6866 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.9 16.7998)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 15.1133 16.4067)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 14.3267 16.0132)" fill="black" />
    <rect width="0.393333" height="0.393333" transform="matrix(-1 0 0 1 13.9333 16.0132)" fill="black" />
    <rect x="2" y="19.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="2" y="18.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="2" y="17.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="2" y="16.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="2" y="15.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="2" y="14.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="2" y="13.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="3" y="12.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="3" y="11.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="4" y="10.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="4" y="9.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="5" y="8.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="6" y="7.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="7" y="6.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="8" y="5.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="9" y="5.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="10" y="4.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="11" y="4.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="12" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="13" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="14" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="15" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="16" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="17" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="18" y="3.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="20" y="27.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="21" y="26.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="22" y="25.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="23" y="24.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="24" y="23.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="25" y="22.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="26" y="21.5" width="1" height="1" fill="white" fill-opacity="0.1" />
    <rect x="26" y="20.5" width="1" height="1" fill="white" fill-opacity="0.1" />
  </svg>
</template>
