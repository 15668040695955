import { createApp } from 'vue'
import i18n from './i18n'
import { createGtm } from '@gtm-support/vue-gtm'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import stores from './stores'
import router from './router'
import App from './App.vue'
import VueToastificationConfig from './configs/vue-toastification-config'

const app = createApp(App)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(Toast, VueToastificationConfig)

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
